<template>
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Suporte</h1>
                    </div>
                </div>
            </div>
        </div>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <InfoBoxV2 class="col-md-4" background="bg-success" icon="ticket-alt" title="Ticket" :description="this.support.id"/>
                    <InfoBoxV2 class="col-md-4" background="bg-warning" icon="tasks" title="Estado" :description="this.support.status.name"/>
                    <InfoBoxV2 class="col-md-4" background="bg-danger" icon="clock" title="Aberto em" :description="`${ new Date(this.support.created_at).toLocaleString() }`"/>
                    <InfoBoxV2 class="col-md-12" background="bg-info" icon="info" title="Assunto" :description="`${this.support.subject}`"/>
                </div>
                <div class="alert alert-info alert-dismissible">
                    <h5><font-awesome-icon icon="info" /> Aviso</h5>
                    <p class="text-justify">Olá, tudo bem? Agradecemos seu contato e informamos que nosso atendimento é de segunda a sexta (exceto feriados), realizado das 09h às 18h. Para que consigamos te ajudar da melhor forma, descreva detalhadamente o que ocorreu e insira informações como link de redação, seu e-mail e nome de cadastro. Com estas informações, seu atendimento será realizado rapidamente! Desde já agradecemos sua mensagem e informamos que em breve retornaremos o contato! 😉</p>
                </div>
                <div class="card card-widget">
                    <div class="card-header">
                        <h3 class="card-title"><font-awesome-icon icon="comments" /> Mensagens</h3>
                    </div>
                    <div class="card-footer card-comments">
                        <div class="card-comment" v-for="message in support.replies" :key="message.id">
                            <img class="img-circle img-sm" src="../../assets/logo.png" alt="User Image">
                            <div class="comment-text">
                                <span class="username">{{ message.admin_id == null ? corrector : 'Redação Online' }} 
                                    <span class="text-muted float-right">{{ new Date(message.created_at).toLocaleString() }}</span>
                                </span>
                                {{ message.message }}
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <img class="img-fluid img-circle img-sm" src="../../assets/logo.png" alt="Alt Text">
                        <div class="img-push">
                            <input type="text" class="form-control form-control-sm" @keyup.enter="send()" v-model="form.message" placeholder="Pressione enter para enviar sua mensagem">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import InfoBoxV2 from '../partials/InfoBoxV2.vue'
import { SUPPORT_INDEX } from '../../constants/api'
import api from '../../services/api' 

export default {
    name: 'Index',
    components: { InfoBoxV2 },
    data() {
        return {
            support: {
                status: {
                    name: ''
                }
            },
            form: {
                message: '',
            },
            corrector: {},
        }
    },
    created() {
        this.corrector = JSON.parse(localStorage.getItem('corrector')).name

        api.get(`${SUPPORT_INDEX}/${this.$route.params.id}`).then((response) => {
            this.support = response.data
        })
    },
    methods: {
        send() {

            this.support.replies.push({
                admin_id : null,
                created_at: new Date().toLocaleString(),
                message: this.form.message
            })

            api.post(`${SUPPORT_INDEX}/${this.$route.params.id}/reply`, this.form).then((response) => {
                console.log(response)
            })

            this.form.message = ''
        }
    }
}
</script>